import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, FormControl, Heading, Input, Link, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { merge } from 'lodash';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { CustomFormLabel } from '@/common/components/form';
import { LoginDetailsFormData, RegistrationFormData } from '@/features/registration/types';
import { loginDetailsSchema } from '@/features/registration/validators';

import {
  selectHasClickedLink,
  selectRegistrationData,
  selectRegistrationSubmitting,
  submitRegistrationRequest,
  updateHasClickedLink,
  updateRegistrationFormData,
} from '../slice';

export function LoginDetails() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const registrationFormData = useAppSelector(selectRegistrationData);
  const hasClickedLink = useAppSelector(selectHasClickedLink);
  const submitting = useAppSelector(selectRegistrationSubmitting);
  const [linkClicked, setLinkClicked] = useState(hasClickedLink);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<LoginDetailsFormData>({
    resolver: zodResolver(loginDetailsSchema),
    defaultValues: {
      ...registrationFormData,
      hasClickedLink: linkClicked,
    },
  });

  const onSubmit = (data: LoginDetailsFormData) => {
    const updatedData = merge({ ...registrationFormData, ...data }) as RegistrationFormData;
    dispatch(updateRegistrationFormData(updatedData));
    dispatch(submitRegistrationRequest(updatedData));
  };

  const handleLinkClick = () => {
    setLinkClicked(true);
    setValue('hasClickedLink', true);
    dispatch(updateHasClickedLink(true));
  };

  return (
    <Box mt={4}>
      <Heading as='h2' size='md' mb={4}>
        {t('registrationPage.titles.loginDetails')}
      </Heading>

      <FormControl as='form' onSubmit={handleSubmit(onSubmit)}>
        <Text fontWeight='bold' mt='6'>
          {t('registrationPage.titles.loginDetailsHelp')}
        </Text>

        <CustomFormLabel htmlFor='email'>
          <Input
            id='email'
            mt={2}
            {...register('formData.email')}
            isInvalid={!!errors.formData?.email}
            autoComplete='email'
          />
          {errors.formData?.email && <Text variant='error'>{errors.formData.email.message}</Text>}
        </CustomFormLabel>

        {registrationFormData.formData.isDefaultWorkflow && (
          <>
            <Checkbox mt={8} w='full' {...register('formData.acceptTerms')} isInvalid={!!errors.formData?.acceptTerms}>
              {t('registrationPage.fields.acceptTerms1')}{' '}
              <Link
                href={`https://www.upside.com/data-and-security/${registrationFormData.formData.vertical}`}
                isExternal
                onClick={handleLinkClick}
              >
                {t('registrationPage.fields.acceptTerms2')}
              </Link>
            </Checkbox>
            {errors.formData?.acceptTerms && <Text variant='error'>{errors.formData.acceptTerms.message}</Text>}
            {errors.hasClickedLink && !linkClicked && <Text variant='error'>{errors.hasClickedLink.message}</Text>}
          </>
        )}
        {!registrationFormData.formData.isDefaultWorkflow && (
          <Box mt={8}>
            {t('registrationPage.fields.acceptTermsStreamlined1')}{' '}
            <Link
              href={`https://www.upside.com/data-and-security/${registrationFormData.formData.vertical}`}
              isExternal
              onClick={handleLinkClick}
            >
              {t('registrationPage.fields.acceptTermsStreamlined2')}
            </Link>
            {t('registrationPage.fields.acceptTermsStreamlined3')}
          </Box>
        )}
        {/* TODO: AM => MD-1613 Adding a CAPTCHA*/}
        <Button mt={5} type='submit' variant='brandPrimary' size='md' px={8} isDisabled={submitting}>
          {t('registrationPage.actions.submit')}
        </Button>
      </FormControl>
    </Box>
  );
}
