import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { Status } from '@/common/interfaces';

import { UserProfileResponse } from './types';

export interface UserState {
  data: UserProfileResponse;
  datas: [UserProfileResponse] | [];
  status: Status;
  passwordStatus: Status;
  error?: string;
}

export const initialState: UserState = {
  data: {
    userProfile: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    userRegistration: {
      isOnboarded: false,
      isDefaultWorkflow: true,
      locationsStepCompleted: false,
      paymentsStepCompleted: false,
      skipPaymentsStep: false,
    },
  },
  datas: [],
  status: Status.Idle,
  passwordStatus: Status.Idle,
  error: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    userProfileFetchRequest: (state) => {
      state.status = Status.Loading;
      state.error = undefined;
    },
    userProfileFetchSuccess: (state, action: PayloadAction<UserProfileResponse>) => {
      state.status = Status.Idle;
      state.data = action.payload;
    },
    userProfileFetchFailure: (state) => {
      state.status = Status.Failed;
      state.error = 'Failed to fetch user data';
    },
    userProfilesFetchRequest: (state) => {
      state.status = Status.Loading;
      state.error = undefined;
    },
    userProfilesFetchSuccess: (state, action: PayloadAction<[UserProfileResponse]>) => {
      state.status = Status.Idle;
      state.datas = action.payload;
    },
    userProfilesFetchFailure: (state) => {
      state.status = Status.Failed;
      state.error = 'Failed to fetch user data';
    },
    updateUserProfileRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    updateUserProfileSucceeded: (state) => {
      state.error = undefined;
    },
    updateUserProfileFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    createUserProfileRequest: (state) => {
      state.error = undefined;
      state.status = Status.Loading;
    },
    createUserProfileSucceeded: (state) => {
      state.error = undefined;
    },
    createUserProfileFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.status = Status.Failed;
    },
    passwordUpdateRequest: (state) => {
      state.passwordStatus = Status.Loading;
    },
    passwordUpdateSuccess: (state) => {
      state.passwordStatus = Status.Idle;
    },
    passwordUpdateFailed: (state, action: PayloadAction<string>) => {
      state.passwordStatus = Status.Failed;
      state.error = action.payload;
    },
  },
});

export const {
  userProfileFetchRequest,
  userProfileFetchSuccess,
  userProfileFetchFailure,
  userProfilesFetchRequest,
  userProfilesFetchSuccess,
  userProfilesFetchFailure,
  updateUserProfileFailed,
  updateUserProfileRequest,
  updateUserProfileSucceeded,
  createUserProfileFailed,
  createUserProfileRequest,
  createUserProfileSucceeded,
  passwordUpdateRequest,
  passwordUpdateSuccess,
  passwordUpdateFailed,
} = profileSlice.actions;

export const selectUserProfile = (state: RootState) => state.profile.data;
export const selectUserProfileStatus = (state: RootState) => state.profile.status;

export const selectUserProfiles = (state: RootState) => state.profile.datas;

export default profileSlice.reducer;
